import React from "react"

import Property from "../../templates/property"

const TestProperty = ({ location }) => {
  return (
    <Property
      location={location}
      pageContext={{
        property: {
          id: "test-123",
          pricePerPersonPerWeek: "$$$$$$$",
          pricePerPersonPerWeekIncludingBills: "$$$$$$$",
          location: {
            latitude: 34.0473346,
            longitude: -118.4165398,
          },
          numberOfBedrooms: 10,
          numberOfBathrooms: 10,
          picture1:
            "https://images.the-bunch.co.uk/properties/010421/Leo%20Gif%20Laughing%20.gif",
          picture2:
            "https://images.the-bunch.co.uk/properties/010421/Bedroom%201%20.jpg",
          picture3:
            "https://images.the-bunch.co.uk/properties/010421/Leo%20Gif%20Laughing%20.gif",
          picture4:
            "https://images.the-bunch.co.uk/properties/010421/Front%20Of%20House%20.jpg",
          picture5:
            "https://images.the-bunch.co.uk/properties/010421/Leo%20Gif%20Laughing%20.gif",
          picture6:
            "https://images.the-bunch.co.uk/properties/010421/Living%20Area%203%20.jpg",
          picture7:
            "https://images.the-bunch.co.uk/properties/010421/Leo%20Gif%20Laughing%20.gif",
          picture8:
            "https://images.the-bunch.co.uk/properties/010421/Outside%20Living%20Area%20.jpg",
          picture9:
            "https://images.the-bunch.co.uk/properties/010421/Leo%20Gif%20Laughing%20.gif",
          picture10:
            "https://images.the-bunch.co.uk/properties/010421/Pool%20Area%20.jpg",
          description1: "- Check the date… WE GOT YOU!",
          description2:
            "- This is actually one of Leo's £3.5 million homes in California",
          description3:
            "- So unfortunately this property isn’t available right now...",
          description4: "- But you're in luck!",
          description5:
            "- You can still find your perfect student pad with all bills included via the button below",
        },
      }}
      disableBookViewing={true}
      disableContactUs={true}
      hidePriceIncludesBills={true}
      hideInformationPoints={true}
      showPropertyFinderLink={true}
    />
  )
}

export default TestProperty
