import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import {
  getMe as apiGetMe,
  favouriteProperty as apiFavouriteProperty,
  unfavouriteProperty as apiUnfavouriteProperty,
  bookViewing as apiBookViewing,
  sendMessage as apiSendMessage,
  makeOffer as apiMakeOffer,
} from "../services/tenantsApi"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import { EmailShareButton, WhatsappShareButton } from "react-share"

import PageWrapper from "../components/PageWrapper"
import PropertyGallery from "../components/propertyListings/propertyGallery/PropertyGallery"
import PropertyInformation from "../components/propertyListings/propertyInformation/PropertyInformation"
import DialogModal from "../components/dialogModal"
import LivingInSection from "../components/livingInSection/LivingInSection"
import Form from "../components/Form"
import AuthenticationDialogModal from "../components/authenticationDialogModal/AuthenticationDialogModal"

import * as propertyStyles from "./property.module.css"

const bookPropertyViewingOptions = {
  fields: [
    {
      name: "existingAccount",
      type: "data",
    },
    {
      placeholder: "First name",
      type: "text",
      required: true,
      name: "firstName",
    },
    {
      placeholder: "Last name",
      type: "text",
      required: true,
      name: "lastName",
    },
    {
      placeholder: "Phone number",
      type: "text",
      required: true,
      name: "phoneNumber",
    },
    {
      placeholder: "Email address",
      type: "email",
      required: true,
      name: "email",
    },
    {
      placeholder: "Password",
      type: "password",
      required: true,
      name: "password",
      showIfFalse: "existingAccount",
    },
    {
      placeholder: "Repeat password",
      type: "password",
      required: true,
      name: "repeatPassword",
      showIfFalse: "existingAccount",
    },
    {
      type: "checkbox",
      label: "Sign me up to hear about the latest Bunch news",
      name: "signUpToNewsletter",
      showIfFalse: "existingAccount",
    },
    {
      type: "checkbox",
      label: (
        <div>
          I agree to Bunch{" "}
          <a
            href="https://www.the-bunch.co.uk/terms-conditions"
            target="_blank"
            rel="noreferrer"
          >
            terms
          </a>{" "}
          and{" "}
          <a
            href="https://www.the-bunch.co.uk/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </a>
        </div>
      ),
      name: "termsAndConditions",
      showIfFalse: "existingAccount",
    },
    {
      label: "Tick if you have a UK guarantor",
      type: "checkbox",
      name: "doesHaveUKGuarantor",
    },
    {
      showIfTrue: "doesHaveUKGuarantor",
      placeholder: "Guarantor name",
      type: "text",
      name: "guarantorName",
    },
    {
      showIfTrue: "doesHaveUKGuarantor",
      placeholder: "Guarantor email",
      type: "email",
      name: "guarantorEmail",
    },
  ],
  submitText: "Submit",
}

const makeOfferStep1Options = {
  fields: [
    {
      name: "existingAccount",
      type: "data",
    },
    {
      placeholder: "First name",
      type: "text",
      required: true,
      name: "firstName",
    },
    {
      placeholder: "Last name",
      type: "text",
      required: true,
      name: "lastName",
    },
    {
      placeholder: "Phone number",
      type: "text",
      required: true,
      name: "phoneNumber",
    },
    {
      placeholder: "Email address",
      type: "email",
      required: true,
      name: "email",
    },
    {
      placeholder: "Password",
      type: "password",
      required: true,
      name: "password",
      showIfFalse: "existingAccount",
    },
    {
      placeholder: "Repeat password",
      type: "password",
      required: true,
      name: "repeatPassword",
      showIfFalse: "existingAccount",
    },
    {
      type: "checkbox",
      label: "Sign me up to hear about the latest Bunch news",
      name: "signUpToNewsletter",
      showIfFalse: "existingAccount",
    },
    {
      type: "checkbox",
      label: (
        <div>
          I agree to Bunch{" "}
          <a
            href="https://www.the-bunch.co.uk/terms-conditions"
            target="_blank"
            rel="noreferrer"
          >
            terms
          </a>{" "}
          and{" "}
          <a
            href="https://www.the-bunch.co.uk/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </a>
        </div>
      ),
      name: "termsAndConditions",
      showIfFalse: "existingAccount",
    },
    {
      placeholder: "Price per person per week",
      type: "number",
      required: true,
      name: "pricePerPersonPerWeek",
    },
  ],
  submitText: "Submit",
}

const sendUsAMessageOptions = {
  fields: [
    {
      name: "existingAccount",
      type: "data",
    },
    {
      placeholder: "First name",
      type: "text",
      required: true,
      name: "firstName",
    },
    {
      placeholder: "Last name",
      type: "text",
      required: true,
      name: "lastName",
    },
    {
      placeholder: "Phone number",
      type: "text",
      required: true,
      name: "phoneNumber",
    },
    {
      placeholder: "Email address",
      type: "email",
      required: true,
      name: "email",
    },
    {
      placeholder: "Password",
      type: "password",
      required: true,
      name: "password",
      showIfFalse: "existingAccount",
    },
    {
      placeholder: "Repeat password",
      type: "password",
      required: true,
      name: "repeatPassword",
      showIfFalse: "existingAccount",
    },
    {
      type: "checkbox",
      label: "Sign me up to hear about the latest Bunch news",
      name: "signUpToNewsletter",
      showIfFalse: "existingAccount",
    },
    {
      type: "checkbox",
      label: (
        <div>
          I agree to Bunch{" "}
          <a
            href="https://www.the-bunch.co.uk/terms-conditions"
            target="_blank"
            rel="noreferrer"
          >
            terms
          </a>{" "}
          and{" "}
          <a
            href="https://www.the-bunch.co.uk/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </a>
        </div>
      ),
      name: "termsAndConditions",
      showIfFalse: "existingAccount",
    },
    {
      placeholder: "Message",
      type: "textarea",
      required: true,
      name: "message",
    },
  ],
  submitText: "Submit",
}

const Property = ({
  location,
  pageContext,
  disableBookViewing,
  disableContactUs,
  hidePriceIncludesBills,
  hideInformationPoints,
  showPropertyFinderLink,
}) => {
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  const [loading, setLoading] = React.useState(true)

  const { property } = pageContext

  const [showBookAViewing, setShowBookAViewing] = React.useState(false)
  // const [bookingViewing, setBookingViewing] = React.useState(false)
  const [bookingAViewing, setBookingAViewing] = React.useState(false)
  const [bookAViewingError, setBookAViewingError] = React.useState(null)
  const [bookAViewingMessage, setBookAViewingMessage] = React.useState(null)
  const [showMakeOffer, setShowMakeOffer] = React.useState(false)
  const [makingAnOffer, setMakingAnOffer] = React.useState(false)
  const [makingAnOfferError, setMakingAnOfferError] = React.useState(null)
  const [makingAnOfferMessage, setMakingAnOfferMessage] = React.useState(null)
  const [showSendUsAMessage, setShowSendUsAMessage] = React.useState(false)
  const [sendingUsAMessage, setSendingUsAMessage] = React.useState(false)
  const [sendUsAMessageError, setSendUsAMessageError] = React.useState(null)
  const [sendUsAMessageMessage, setSendUsAMessageMessage] = React.useState(null)
  const [shareProperty, setShowShareProperty] = React.useState(false)

  const [showMustBeLoggedIn, setShowMustBeLoggedIn] = React.useState(false)
  const [
    togglingFavouriteProperty,
    setTogglingFavouriteProperty,
  ] = React.useState(false)

  const propertyAddress = [
    property.addressLine1,
    property.addressLine2,
    property.city,
    property.postcode,
    property.country,
  ]
    .filter(addressLine => addressLine)
    .join(", ")

  const propertiesSearchStr =
    typeof window !== "undefined" &&
    localStorage.getItem("theBunch-propertiesSearch")
  let propertiesSearchQueryStrings = null
  if (propertiesSearchStr) {
    let propertiesSearch = JSON.parse(propertiesSearchStr)
    propertiesSearchQueryStrings = Object.keys(propertiesSearch)
      .filter(key => propertiesSearch[key])
      .map(key => `${key}=${propertiesSearch[key]}`)
      .join("&")

    if (propertiesSearchQueryStrings.length > 0) {
      propertiesSearchQueryStrings = "?" + propertiesSearchQueryStrings
    }
  }

  const bookAViewing = async payload => {
    if (!context.tenantUser) {
      if (!payload.termsAndConditions) {
        return setBookAViewingError("Please agree to the terms and conditions")
      }

      if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(payload.password)) {
        return setBookAViewingError("Password does not match requirements")
      }

      if (payload.password !== payload.repeatPassword) {
        return setBookAViewingError("Passwords do not match")
      }

      if (!payload || !payload.email || !payload.password) {
        return setBookAViewingError("Please complete all fields")
      }
    }

    try {
      setBookingAViewing(true)
      setBookAViewingError("")
      setBookAViewingMessage("")
      const data = await apiBookViewing(property.id, {
        ...payload,
      })
      if (data.success) {
        if (data.value.accessToken) {
          dispatch({
            type: "loggedInToTenantsPortal",
            tokens: {
              accessToken: data.value.accessToken,
              refreshToken: data.value.refreshToken,
            },
          })
        }
        setBookAViewingMessage("All set! We'll be in contact.")
      } else if (data.errors) {
        setBookAViewingError(data.errors[0].reason)
      } else {
        setBookAViewingError("An error occurred, please try again")
      }
      setBookingAViewing(false)
    } catch (e) {
      console.log(e)
      setBookAViewingError("An error occurred, please try again.")
      setBookingAViewing(false)
    }
  }

  const makeOffer = async (payload, callback) => {
    if (!context.tenantUser) {
      if (!payload.termsAndConditions) {
        return setMakingAnOfferError("Please agree to the terms and conditions")
      }

      if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(payload.password)) {
        return setMakingAnOfferError("Password does not match requirements")
      }

      if (payload.password !== payload.repeatPassword) {
        return setMakingAnOfferError("Passwords do not match")
      }

      if (!payload || !payload.email || !payload.password) {
        return setMakingAnOfferError("Please complete all fields")
      }
    }

    try {
      setMakingAnOffer(true)
      setMakingAnOfferError("")
      setMakingAnOfferMessage("")
      const data = await apiMakeOffer(property.id, {
        ...payload,
      })
      if (data.success) {
        if (data.value.accessToken) {
          dispatch({
            type: "loggedInToTenantsPortal",
            tokens: {
              accessToken: data.value.accessToken,
              refreshToken: data.value.refreshToken,
            },
          })
        }
        setMakingAnOfferMessage("All set! We'll be in contact.")
      } else if (data.errors) {
        setMakingAnOfferError(data.errors[0].reason)
      } else {
        setMakingAnOfferError("An error occurred, please try again")
      }
      setMakingAnOffer(false)
    } catch (e) {
      console.log(e)
      setMakingAnOfferError("An error occurred, please try again.")
      setMakingAnOffer(false)
    }
  }

  const sendUsAMessage = async payload => {
    if (!context.tenantUser) {
      if (!payload.termsAndConditions) {
        return setSendUsAMessageError(
          "Please agree to the terms and conditions"
        )
      }

      if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(payload.password)) {
        return setSendUsAMessageError("Password does not match requirements")
      }

      if (payload.password !== payload.repeatPassword) {
        return setSendUsAMessageError("Passwords do not match")
      }

      if (!payload || !payload.email || !payload.password) {
        return setSendUsAMessageError("Please complete all fields")
      }
    }

    try {
      setSendingUsAMessage(true)
      setSendUsAMessageMessage("")
      setSendUsAMessageError("")
      await apiSendMessage(property.id, {
        ...payload,
      })
      setSendUsAMessageMessage("All set! We'll be in contact.")
      setSendingUsAMessage(false)
    } catch (e) {
      console.log(e)
      setSendingUsAMessage("An error occurred, please try again.")
      setSendingUsAMessage(false)
    }
  }

  const toggleFavouriteProperty = async (propertyId, wasLoggedOut) => {
    if (context.loggedInToTenantsPortal && context.tenantUser) {
      setTogglingFavouriteProperty(true)

      let existingFavourite = null
      if (
        context.tenantUser.favourites &&
        context.tenantUser.favourites.length > 0
      ) {
        existingFavourite = context.tenantUser.favourites.find(
          _ => _.propertyId === propertyId
        )
      }

      if (existingFavourite && !wasLoggedOut) {
        try {
          await apiUnfavouriteProperty(propertyId)
        } catch (e) {
          console.log(e)
        }
      } else {
        try {
          await apiFavouriteProperty(propertyId)
        } catch (e) {
          console.log(e)
          setTogglingFavouriteProperty(false)
        }
      }

      try {
        const data = await apiGetMe()
        if (data.user) {
          dispatch({
            type: "storeTenantUser",
            tenantUser: data.user,
          })
        }
        setTogglingFavouriteProperty(false)
      } catch (e) {
        console.log(e)
        setTogglingFavouriteProperty(false)
      }
    } else {
      setShowMustBeLoggedIn(true)
      localStorage.setItem(
        "theBunch-favouritePropertyOnLogin",
        propertyId.toString()
      )
    }
  }

  var url = ""
  if (typeof window != "undefined") {
    url = `${window.location.origin}/property/${property.id}`
  }

  const favouritedProperty =
    context.tenantUser &&
    context.tenantUser.favourites &&
    context.tenantUser.favourites.find(_ => _.propertyId === property.id)

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
  }, [])

  return (
    <PageWrapper location={location} showLoadingIndicator={loading}>
      {/* <FilterBar
        numberOfBedrooms={numberOfBedrooms}
        setNumberOfBedrooms={value => setNumberOfBedrooms(value)}
        minBeds={minBeds}
        setMinBeds={value => setMinBeds(value)}
        maxBeds={maxBeds}
        setMaxBeds={value => setMaxBeds(value)}
        minPrice={minPrice}
        setMinPrice={value => setMinPrice(value)}
        maxPrice={maxPrice}
        setMaxPrice={value => setMaxPrice(value)}
      /> */}
      <div className={propertyStyles.property}>
        <div className={propertyStyles.propertyControls}>
          <div className={propertyStyles.backToSearchResults}>
            <i style={{ fontSize: 20 }} className="material-icons">
              arrow_back
            </i>{" "}
            <Link to={`/results${propertiesSearchQueryStrings}`}>
              <span>Back to search results</span>
            </Link>
          </div>
          <div className={propertyStyles.propertyActions}>
            <div
              className={propertyStyles.propertyAction}
              onClick={() => setShowShareProperty(true)}
              onKeyPress={() => setShowShareProperty(true)}
              role="button"
              tabIndex="0"
            >
              <i
                style={{ fontSize: 20, marginRight: 7 }}
                className="material-icons"
              >
                share
              </i>{" "}
              Share
            </div>
            <div
              className={propertyStyles.propertyAction}
              onClick={() =>
                !togglingFavouriteProperty &&
                toggleFavouriteProperty(property.id)
              }
              onKeyPress={() =>
                !togglingFavouriteProperty &&
                toggleFavouriteProperty(property.id)
              }
              role="button"
              tabIndex="0"
            >
              <i
                style={{ fontSize: 20, marginRight: 7 }}
                className="material-icons"
              >
                {favouritedProperty ? "favorite" : "favorite_border"}
              </i>{" "}
              Favourite
            </div>
          </div>
        </div>
        <div>
          <div className={propertyStyles.mobilePropertyAddress}>
            {propertyAddress}
          </div>
          <div className={propertyStyles.propertyGalleryWrapper}>
            <PropertyGallery
              showThumbnails={true}
              images={Object.keys(property)
                .filter(key => key.startsWith("picture") && property[key])
                .map(key => property[key])}
            />
          </div>
          <div className={propertyStyles.propertyInformationWrapper}>
            <PropertyInformation
              property={property}
              address={propertyAddress}
              pricePerPersonPerWeek={
                property.pricePerPersonPerWeekIncludingBills
              }
              numberOfBathrooms={property.numberOfBathrooms}
              numberOfBedrooms={property.numberOfBedrooms}
              onBookViewing={() => setShowBookAViewing(true)}
              onMakeOffer={() => setShowMakeOffer(true)}
              // bookingViewing={bookingViewing}
              bookAViewing={() => setShowBookAViewing(true)}
              onSendUsAMessage={() => setShowSendUsAMessage(true)}
              disableBookViewing={disableBookViewing}
              disableContactUs={disableContactUs}
              hidePriceIncludesBills={hidePriceIncludesBills}
              hideInformationPoints={hideInformationPoints}
              showPropertyFinderLink={showPropertyFinderLink}
            />
          </div>
        </div>
      </div>
      <LivingInSection city={property.city && property.city.toLowerCase()} />

      <DialogModal
        open={shareProperty}
        onClose={() => setShowShareProperty(false)}
        hideCancelButton={true}
      >
        <div style={{ padding: 20, paddingBottom: 0 }}>
          <div style={{ fontSize: 22, marginBottom: 15 }}>
            Share this property
          </div>
          <div>
            <EmailShareButton
              subject="Hey, I've found a great property"
              url={url}
            >
              <img
                className={propertyStyles.shareWithIcon}
                src={require("../assets/mail.png")}
                alt="Send an email"
              />
              <div>Send an email</div>
            </EmailShareButton>
            <WhatsappShareButton
              title="Hey, I've found a great property"
              url={url}
            >
              <img
                className={propertyStyles.shareWithIcon}
                src={require("../assets/whatsapp.png")}
                alt="Share on Whatsapp"
              />
              <div>Share on Whatsapp</div>
            </WhatsappShareButton>
          </div>
        </div>
      </DialogModal>

      <DialogModal
        open={showBookAViewing}
        onClose={() => setShowBookAViewing(false)}
        hideCancelButton={true}
      >
        <div style={{ padding: 20, paddingBottom: 0 }}>
          <div style={{ fontSize: 22, marginBottom: 15 }}>Book a viewing</div>
          <Form
            initialPayload={
              context.tenantUser
                ? {
                  firstName: context.tenantUser.firstName,
                  lastName: context.tenantUser.lastName,
                  email: context.tenantUser.email,
                  phoneNumber: context.tenantUser.phone,
                  existingAccount: true,
                }
                : {
                  existingAccount: false,
                }
            }
            options={bookPropertyViewingOptions}
            onSubmit={payload => bookAViewing(payload)}
            submitting={bookingAViewing}
            apiErrorMessage={bookAViewingError}
            apiSuccessMessage={bookAViewingMessage}
          />
        </div>
      </DialogModal>

      <DialogModal
        open={showMakeOffer}
        onClose={() => {
          setShowMakeOffer(false)
        }}
        hideCancelButton={true}
      >
        <div style={{ padding: 20, paddingBottom: 0 }}>
          <div style={{ fontSize: 22, marginBottom: 15 }}>Make an offer</div>
          <Form
            initialPayload={
              context.tenantUser
                ? {
                  firstName: context.tenantUser.firstName,
                  lastName: context.tenantUser.lastName,
                  email: context.tenantUser.email,
                  phoneNumber: context.tenantUser.phone,
                  existingAccount: true,
                }
                : {
                  existingAccount: false,
                }
            }
            options={makeOfferStep1Options}
            onSubmit={(payload, callback) => makeOffer(payload, callback)}
            submitting={makingAnOffer}
            apiErrorMessage={makingAnOfferError}
            apiSuccessMessage={makingAnOfferMessage}
          />
        </div>
      </DialogModal>

      <DialogModal
        open={showSendUsAMessage}
        onClose={() => setShowSendUsAMessage(false)}
        hideCancelButton={true}
      >
        <div style={{ padding: 20, paddingBottom: 0 }}>
          <div style={{ fontSize: 22, marginBottom: 15 }}>
            Send us a message
          </div>
          <Form
            initialPayload={
              context.tenantUser
                ? {
                  firstName: context.tenantUser.firstName,
                  lastName: context.tenantUser.lastName,
                  email: context.tenantUser.email,
                  phoneNumber: context.tenantUser.phone,
                  existingAccount: true,
                }
                : {
                  existingAccount: false,
                }
            }
            options={sendUsAMessageOptions}
            onSubmit={payload => sendUsAMessage(payload)}
            submitting={sendingUsAMessage}
            apiErrorMessage={sendUsAMessageError}
            apiSuccessMessage={sendUsAMessageMessage}
          />
        </div>
      </DialogModal>

      <AuthenticationDialogModal
        open={showMustBeLoggedIn}
        onClose={() => setShowMustBeLoggedIn(false)}
        onSuccess={() => {
          setShowMustBeLoggedIn(false)
        }}
      />
    </PageWrapper>
  )
}

export default Property
