import React, { useContext } from "react"
import clsx from "clsx"
import { Link } from "gatsby"

import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"

import { GlobalDispatchContext } from "../../context/GlobalContextProvider"
import {
  register as apiRegister,
  login as apiLogin,
} from "../../services/tenantsApi"

import AuthenticationWrapper from "../authenticationWrapper/AuthenticationWrapper"
import Form from "../Form"

const loginFormOptions = {
  fields: [
    {
      name: "email",
      placeholder: "Email address",
      type: "email",
      required: true,
    },
    {
      name: "password",
      placeholder: "Password",
      type: "password",
      required: true,
    },
  ],
  submitText: "Sign in",
}

const registerFormOptions = {
  fields: [
    [
      {
        placeholder: "First name",
        type: "name",
        required: true,
        name: "firstName",
      },
      {
        placeholder: "Last name",
        type: "name",
        required: true,
        name: "lastName",
      },
    ],
    {
      placeholder: "Email address",
      type: "email",
      required: true,
      name: "email",
    },
    {
      placeholder: "Phone number",
      type: "tel",
      required: true,
      name: "phone",
    },
    {
      label: "University",
      type: "dropdown",
      required: true,
      name: "university",
      options: [
        {
          label: "Bristol",
          value: "Bristol",
        },
        {
          label: "Manchester",
          value: "Manchester",
        },
        {
          label: "Leeds",
          value: "Leeds",
        },
      ],
    },
    {
      label: "House size",
      type: "range",
      required: true,
      name: "numberOfBedrooms",
      min: 1,
      max: 12,
      defaultValue: 1,
      marks: [
        {
          value: 1,
          label: "1",
        },
        {
          value: 12,
          label: "12",
        },
      ],
    },
    {
      placeholder: "Password",
      type: "password",
      required: true,
      name: "password",
      helperText: "min 8 characters, 1 upper case, 1 number",
    },
    {
      placeholder: "Repeat password",
      type: "password",
      required: true,
      name: "repeatPassword",
    },
    {
      type: "checkbox",
      label: (
        <div>
          I agree to Bunch{" "}
          <a
            href="https://www.the-bunch.co.uk/terms-conditions"
            target="_blank"
            rel="noreferrer"
          >
            terms
          </a>{" "}
          and{" "}
          <a
            href="https://www.the-bunch.co.uk/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </a>
        </div>
      ),
      name: "termsAndConditions",
    },
  ],
  submitText: "Sign up",
}

const styles = theme => ({
  root: {
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 80px)",
    },
  },
  fullScreen: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
  },
})

function AuthenticationDialogModal({
  classes,
  open,
  title,
  onClose,
  fullScreen,
  onSuccess,
}) {
  const dispatch = useContext(GlobalDispatchContext)

  const [registering, setRegistering] = React.useState(false)
  const [registerError, setRegisterError] = React.useState("")
  const [loggingIn, setLoggingIn] = React.useState(false)
  const [loginError, setLoginError] = React.useState("")
  const [view, setView] = React.useState("register")

  const register = async payload => {
    if (!payload.termsAndConditions) {
      return setRegisterError("Please agree to the terms and conditions")
    }

    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(payload.password)) {
      return setRegisterError("Password does not match requirements")
    }

    if (payload.password !== payload.repeatPassword) {
      return setRegisterError("Passwords do not match")
    }

    if (!payload || !payload.email || !payload.password) {
      return setRegisterError("Please complete all fields")
    }

    setRegisterError("")

    try {
      setRegistering(true)
      const data = await apiRegister(payload)

      if (data.success) {
        dispatch({
          type: "loggedInToTenantsPortal",
          tokens: {
            accessToken: data.value.accessToken,
            refreshToken: data.value.refreshToken,
          },
        })

        onSuccess()
      } else if (data.errors) {
        setRegisterError(data.errors[0].reason)
      } else {
        setRegisterError("An error occurred trying to register")
      }
      setRegistering(false)
    } catch (e) {
      setRegistering(false)
      setRegisterError(e.message)
    }
  }

  const login = async payload => {
    if (!payload || !payload.email || !payload.password) {
      return setLoginError("Please complete all fields")
    }

    setLoginError("")

    try {
      setLoggingIn(true)
      const data = await apiLogin(payload)

      if (data.success) {
        dispatch({
          type: "loggedInToTenantsPortal",
          tokens: {
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          },
        })
        onSuccess()
      } else if (data.errors) {
        setLoginError(data.errors[0].reason)
      } else {
        setLoginError("An error occurred trying to login")
      }
      setLoggingIn(false)
    } catch (e) {
      setLoggingIn(false)
      setLoginError(e.message)
    }
  }

  const renderForm = () => {
    if (view === "register") {
      return (
        <AuthenticationWrapper
          description={
            "Sign in or create an account to access your tenant portal"
          }
          style={{ width: "100%" }}
        >
          <Form
            initialPayload={{ numberOfBedrooms: 1 }}
            options={registerFormOptions}
            onSubmit={payload => register(payload)}
            submitting={registering}
            apiErrorMessage={registerError}
          />
          <div>
            Already have an account?{" "}
            <span
              style={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={() => setView("login")}
              onKeyPress={() => setView("login")}
              role="button"
              tabIndex="0"
            >
              Sign in
            </span>
          </div>
        </AuthenticationWrapper>
      )
    } else if (view === "login") {
      return (
        <AuthenticationWrapper
          description="Sign in or create an account to access your tenant portal"
          style={{ width: "100%" }}
        >
          <Form
            options={loginFormOptions}
            onSubmit={payload => login(payload)}
            submitting={loggingIn}
            apiErrorMessage={loginError}
          />
          <Link to="/tenants/app/forgot-password">
            <div>Forgot your password</div>
          </Link>
          <div>
            Don't have an account?{" "}
            <div
              style={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={() => setView("register")}
              onKeyPress={() => setView("register")}
              tabIndex="0"
              role="button"
            >
              Create account
            </div>
          </div>
        </AuthenticationWrapper>
      )
    }
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onClose}
      fullScreen={fullScreen}
      PaperProps={{
        classes: {
          root: clsx(classes.root, {
            [classes.fullScreen]: fullScreen,
          }),
        },
      }}
    >
      {title && (
        <DialogTitle id="create-task-dialog-title">{title}</DialogTitle>
      )}
      {renderForm()}
    </Dialog>
  )
}

export default withStyles(styles)(AuthenticationDialogModal)
