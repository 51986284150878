import React, { useRef } from "react"
import Tooltip from "@material-ui/core/Tooltip"
import InfoIcon from "@material-ui/icons/Info"
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"

import Button from "../../button/Button"
import SecondaryButton from "../../secondaryButton/SecondaryButton"

import * as propertyInformationStyles from "./propertyInformation.module.css"

const useStyles = makeStyles({
  tooltip: {
    fontSize: 17,
  },
})

const PropertyMap = withGoogleMap(props => (
  <GoogleMap ref={props.mapRef} defaultZoom={14} defaultCenter={props.center}>
    {props.property && (
      <Marker
        onClick={() =>
          props.onMarkerClick && props.onMarkerClick(props.property)
        }
        position={{
          lat: props.property.location.latitude,
          lng: props.property.location.longitude,
        }}
      />
    )}
  </GoogleMap>
))

export default function PropertyInformation({
  property,
  address,
  pricePerPersonPerWeek,
  numberOfBathrooms,
  numberOfBedrooms,
  onBookViewing,
  onSendUsAMessage,
  disableBookViewing,
  disableContactUs,
  hidePriceIncludesBills,
  hideInformationPoints,
  showPropertyFinderLink,
}) {
  const classes = useStyles()
  const mapRef = useRef(null)

  const renderDescription = () => {
    let description = []

    for (let i = 0; i < 10; i++) {
      let descriptionValue = property[`description${i}`]
      description.push(
        <div style={{ marginBottom: 10 }}>{descriptionValue}</div>
      )
    }

    return description
  }

  return (
    <div className={propertyInformationStyles.propertyInformation}>
      <div className={propertyInformationStyles.propertyAddress}>{address}</div>
      <div className={propertyInformationStyles.propertyNumbers}>
        <div className={propertyInformationStyles.pricePerWeek}>
          £
          {typeof input === "number"
            ? pricePerPersonPerWeek.toFixed(2)
            : pricePerPersonPerWeek}{" "}
          per person per week
          {!hideInformationPoints && (
            <Tooltip
              title="This price includes household bills"
              classes={{ tooltip: classes.tooltip }}
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              role="button"
              tabIndex="0"
            >
              <InfoIcon style={{ marginBottom: 4, marginLeft: 5 }} />
            </Tooltip>
          )}
        </div>
        <div>
          <div className={propertyInformationStyles.propertyNumber}>
            <img
              alt="Bedrooms"
              src={require("../../../assets/bedroom.png")}
              className={propertyInformationStyles.propertyNumberIcon}
            />{" "}
            {numberOfBedrooms}bd
          </div>
          <div className={propertyInformationStyles.propertyNumber}>
            <img
              alt="Bathrooms"
              src={require("../../../assets/bathroom.png")}
              className={propertyInformationStyles.propertyNumberIcon}
            />{" "}
            {numberOfBathrooms}ba
          </div>
        </div>
      </div>
      <div className={propertyInformationStyles.propertyActionButtons}>
        <Button
          title="Book viewing"
          style={{ width: 175, marginRight: 10, marginBottom: 10 }}
          onClick={() =>
            !disableBookViewing && onBookViewing && onBookViewing()
          }
        />
        {/* <Button
          title="Make offer"
          style={{ marginRight: 10, marginBottom: 10, width: 175 }}
          onClick={() => onMakeOffer && onMakeOffer()}
        /> */}
        <SecondaryButton
          title="Contact us"
          style={{ width: 175, marginBottom: 10, marginRight: 10 }}
          onClick={() =>
            !disableContactUs && onSendUsAMessage && onSendUsAMessage()
          }
        />
        {!hideInformationPoints && (
          <Tooltip
            title="Yet to return to your University city and want to sign for this property without a viewing? Click 'Book viewing' and enter your details. The Letting Agent will then be in contact to sort this for you."
            classes={{ tooltip: classes.tooltip }}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            role="button"
            tabIndex="0"
          >
            <InfoIcon />
          </Tooltip>
        )}
      </div>
      <div style={{ marginBottom: 0, padding: "20px 0" }}>
        {renderDescription()}
        {!hidePriceIncludesBills && (
          <div>- This price includes household bills</div>
        )}
        {showPropertyFinderLink && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <Link to="https://www.the-bunch.co.uk/tenants/property-finder">
              <Button
                title="Property finder"
                style={{ width: 175, marginRight: 10, marginBottom: 10 }}
                onClick={() =>
                  !disableBookViewing && onBookViewing && onBookViewing()
                }
              />
            </Link>
          </div>
        )}
      </div>
      <PropertyMap
        containerElement={<div style={{ height: `300px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        center={{
          lat: property.location.latitude,
          lng: property.location.longitude,
        }}
        isMarkerShown={true}
        property={property}
        mapRef={mapRef}
      />
    </div>
  )
}
